@use '../../../../styles' as *;

.select {
  min-width: 20rem;
  margin-inline: auto;
}

@media (max-width: $tablet-screen) {
  .select {
    min-width: max-content;
  }
}