@use '../../styles' as *;

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: $nav-size;
  padding: 0 2rem;
  width: 100%;
  z-index: $nav-index;

  background-color: $background;
}

.logo {
  cursor: pointer;
}
