@use '../../../styles' as *;

.backButton {
  display: flex;
  margin-left: auto;
}

.infoTitle {
  font-size: 2rem;
  text-align: center;
}

.sectionMargins > * {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}