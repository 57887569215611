@use '../../../../styles' as *;

.groupName {
  margin: 0.5rem 0;
}

.tableWrapper {
  display: flex;
  justify-content: center;
  gap: 0rem 3rem;
  flex-wrap: wrap;
}

.table {
  @include table;
  margin: 0;
  display: block;
  width: auto;
  text-align: center;
  box-sizing: border-box;
  overflow-x: auto;
  box-shadow: 0px 6px 17px $table-box-shadow;
}

.firstTable tbody td:nth-child(2) {
  text-align: left;
}

.separatorLine {
  border: none;
  border-top: 1px solid $chestnut;
  margin: 1.5rem 0;
}

.stageLabel {
  color: $chestnut;
  font-size: 1.2rem;
  text-align: center;
}

.tableHead {
  text-align: center;
  padding: 0.3rem !important;
}

.showMatchesButton {
  width: 100%;
  align-items: center;
  color: $chestnut;
  background-color: $background;
  border: 0;
}

.showMatchesButton:hover {
  width: 100%;
  align-items: center;
  color: $chestnut;
  background-color: $background;
  border: 0;
}

.buttonGap {
  margin-left: 10px;
}