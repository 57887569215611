@use '../../../../styles' as *;
.dropdown{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.dropdownElements{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 18rem;
  justify-content: center;
  flex-direction: column;
  position: relative;
  color: $chestnut;
}

.select {
  position: relative;
  background-color: white;
  cursor: pointer;
}

.selected {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: $chestnut;
  border-radius: 0.8rem;
  border: 2px solid $chestnut;

  .selectedValue {
    padding: .5rem;
  }
}

.categoryInDropdown {
  display: flex;
  justify-content: space-between;
  color: $chestnut;

  .selectedValueCat {
    padding: .5rem;
  }
}

.categoryInDropdown:hover {
  background-color: $select-option-hover;
}

.dropdownIcon {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: .75rem;
}

.selectButton{
  border: none;
  background-color: white;
  border-bottom: $chestnut;
  color: $chestnut;
}

.menu {
  width: 100%;
  position: absolute;
  top: 44px;
  z-index: $select-dropdown-index;
  background: white;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  border: 1px solid $chestnut;
}
.menuElement{
  border-bottom: 1px solid $chestnut;
}

.teamMenu {
  list-style-type: none;
  padding: 0px;
}

.teamMenuElement{
  margin: 0;
  background-color: white;
}

.teamMenuElement:hover {
  background-color: $select-option-hover;
}

.separatorLine {
  border: none;
  border-top: 1px solid $chestnut;
  margin: 1.5rem 0;
}

table {
  margin-right: auto;
  margin-left: auto;
  width: 18rem;
}

.playersHeader{
  text-align: center;
  margin-bottom: 1rem;
}

.playerCard {
  background-color: whitesmoke;
  padding: .5rem;
  border: 2px solid $chestnut;
  border-radius: 0.8rem;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
  width: 12rem;
  margin-bottom: 1rem;
  text-align: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 40rem;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}

th {
  text-align: left;
}