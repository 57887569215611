@use '../../styles' as *;

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-inline: auto;
  max-width: 45rem;
}

.tab {
  flex: 1;
}

@media screen and (max-width: $mobile-screen) {
  .tab {
    font-size: 1rem;
  }
}
