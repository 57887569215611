/* BREAKPOINTS */
$desktop-screen: 1024px;
$tablet-screen: 768px;
$mobile-screen: 480px;

/* INDICES */
$z-base: 1;
$modal-index: $z-base + 1;
$dialog-index: $modal-index + 1;
$second-level-modal-index: $z-base + 2;
$select-dropdown-index: $z-base + 9;
$nav-index: $z-base + 10;

/* COLORS */
$black: #000;
$chorcoal: #414141;
$dark-grey: #787878;
$white: #fff;
$light-grey: #F9F9F9;
$light-grey-hover: #eeeded;
$soft-pink: #FFA6A6;
$chestnut: #C36060;
$basic-red: #E11A24;
$pirate-gold: #B78000;
$dark-grass: #2F8E02;

$background: #fff;

/* BUTTONS */
$button-text_disabled: #00000042;
$button-color_disabled: #eeeeee;

$basic-red_outlined-hover: #ff5760;
$basic-red_outlined-active: #fff0f0;
$basic-red_contained-hover: #d40000;
$basic-red_contained-active: #ff5858;
$basic-red_text-hover: #d40000;
$basic-red_text-active: #ff5858;
$basic-red_tab-hover: $soft-pink;

$chestnut_outlined-hover: #da6d6d;
$chestnut_outlined-active: #faebeb;
$chestnut_contained-hover: #d16161;
$chestnut_contained-active: #c87171;
$chestnut_text-hover: #d16161;
$chestnut_text-active: #c87171;
$chestnut-red_tab-hover: #d59494;

/* NAVBAR */
$nav-size: 5rem;
$viewport-main-height: calc(100vh - #{$nav-size});

/* TABLES */
$table-box-shadow: rgba(242, 20, 20, 0.1);
$table-radius: 9px;

/* SELECT */
$select-option-hover: #c360601d;
$select-box-shadow: #c36060;

/*
  ----------------------------
  ----------------------------
  -----DEPRECATED-STYLES------
  ----------------------------
  ----------------------------
*/

/*Whites and greys*/
$white: #ffffff;
$grey: #cacaca;
$dark-grey-hover: #e7e7e7;

/*Colors*/
$lavenderBlush: #fff5f5;
$salmon: #ff7573;
$salmon-hover: #ff8c8a;
$bean-red: #f86161;
$red: #ff0000;
$peach-puff: #fcd9c0;
$peach-puff-hover: #ffceae;
$off-white: #ffffe7;
$ecru: #ffffc4;
$aquaspring: #e2ffe2;
$snow-flurry: #e3ffc8;
$greenish-teal: #27be96;
$greenish-teal-hover: #53c7a8;
$green: #006900;
$azure: #edfbff; 
$link-water: #d9e5ff;
$dark-turquoise: #00cecb;
$blue: #4472c4;

$transparent-grey: rgba(45, 45, 45, 0.2);

/*Modal*/
$modal-border: $dark-grey;
$modal-transparency: $transparent-grey;
$modal-background: $light-grey;

/*Notification*/
$notification-index: $z-base + 3;
$notification-success: #e3fcef;
$notification-error: #ffebe6;
$notification-warning: #fffae6;
$notification-message: #e2e2e2;
$notification-success-font: #006644;
$notification-error-font: #bf2600;
$notification-warning-font: #ff8b00;
$notification-message-font: #505f79;

/*MultiPart Form*/
$form-tab: $white;
$form-tab-selected: #c9c9fa;
$form-tab-verified: #d2fdd2;
$form-tab-error: #ffd6d6;
$form-tab-text-verified: #4fbb4f;
$form-tab-text-error: $bean-red;
$form-selected-value: #c9c9fa;
$form-transparent-selected: rgba(142, 142, 255, 0.34);

/*Penalty Card*/
$card-red-color: #ff4747;
$card-yellow-color: #fdfd54;
$card-green-color: #4ac44a;
$card-border-color: #000000;
$card-outline-color: #8686ff;

/*Tooltip*/
$tooltip-index: $z-base + 3;
$tooltip-background: $black;
$tooltip-text-color: $white;

/*Tables*/
$empty-cell-fill: #d4d4d4;
$table-border-color: #000000;
$table-body-color: #ffffff;

/*Matches*/
$selected-color: #FFEFCE;
$highlight-color: #E9FAD6;
$hover-color: #E2E2FF;
$filter-background: $white;
$filter-border: #575757;

/*Other*/
$error-background: rgba(255, 0, 0, 0.123);
$error-text-color: $bean-red;

$register-list-border: $dark-grey;
$register-list-background: $light-grey;

$arrow-color: $dark-grey;
$spinner-color: #c6c6c6;
$spinner-spinning-part: #565656;

$table-hover: #e0e0e0;

$linkButton: #efefef;
$linkButton-hover: #e2e2e2;
$linkButton-border: #767676;
