.rotateLeft {
  transform: rotate(0deg);
}

.rotateTop {
  transform: rotate(90deg);
}

.rotateRight {
  transform: rotate(180deg);
}

.rotateBottom {
  transform: rotate(270deg);
}
