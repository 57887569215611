@use '../../styles' as *;

.select {
  position: relative;
  width: 12.5rem;
  background-color: $white;
  cursor: pointer;
}

.selected {
  display: flex;
  align-items: center;

  color: $chestnut;
  border-radius: 0.8rem;
  border: 2px solid $chestnut;

  .selectedValue {
    flex: 1 1 0;
    padding: .5rem;
  }
}

.dropdown {
  position: absolute;
  background: $white;
  width: 100%;
  margin-top: 3px;
  border-radius: 2px;
  z-index: $select-dropdown-index;
  
  -webkit-box-shadow: 0px 0px 5px 0px $select-box-shadow;
  -moz-box-shadow: 0px 0px 5px 0px $select-box-shadow;
  box-shadow: 0px 0px 5px 0px $select-box-shadow;

  max-height: 15rem;
  overflow-y: auto;

  .option {
    color: $chestnut;
    padding: .2rem;
    padding-left: .5rem;
    background: none;
    border: none;
    display: block;
    width: 100%;
    text-align: left;

    &:hover {
      background: $select-option-hover;
    }
  }
}

.upward {
  top: auto;
  bottom: 100%;
}

.clearIcon {
  border: none;
  background-color: inherit;
  padding: 0 0.4rem;
  color:  $dark-grey;

  &:hover {
    color: $black;
  }
}

.dropdownIcon {
  margin-right: .75rem;
}

.spinnerIcon {
  @include spinner-icon;
}