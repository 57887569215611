@use '../../../../styles' as *;

.tableOverflow {
  overflow-x: auto;
}

.table {
  @include table;
  box-shadow: 0px 6px 17px $table-box-shadow;
  
  tr:nth-child(even) td {
    background-color: $light-grey;
  }
}