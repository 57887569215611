@use '../../../../styles' as *;

.tableWrapper {
  overflow: auto;
  border-radius: $table-radius;
  box-shadow: 0px 6px 17px $table-box-shadow;
}

.table {
  @include table;
  width: 100%;
}

.greyoutRow {
  td {
    background-color: $light-grey;
  }
}

.hide {
  display: none;
}

.animateBlink {
  animation: blink 3s ease infinite;
}

@keyframes blink {
  from, to {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
}

/* STATUS */
.playerStatus {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.4rem;
}

.inProgress {
  color: $pirate-gold;

  svg circle {
    fill: $pirate-gold;
  }
}

.finished {
  color: $dark-grass;

  svg circle {
    fill: $dark-grass;
  }
}

.downloadPDF {
  display: block;
  margin-left: auto;
}
