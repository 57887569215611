@use '../../styles/helpers/variables' as *;

.notification {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;

  cursor: pointer;
  z-index: $notification-index;
}

.notificationText {
  margin-left: auto;
  font-size: 1.75em;
  word-break: break-word;
}

.closeText {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;

  background: inherit;
  color: inherit;
  border: none;
  height: 1.5rem;
  fill: currentColor;
}

.error {
  background: $notification-error;
  color: $notification-error-font;
}

.message {
  background: $notification-message;
  color: $notification-message-font;
}

.success {
  background: $notification-success;
  color: $notification-success-font;
}

.warning {
  background: $notification-warning;
  color: $notification-warning-font;
}