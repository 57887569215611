@use "../../../styles" as *;

.errorLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - #{$nav-size});

  .errorTitle {
    text-align: center;
    font-size: 5rem;
  }

  .errorDescription {
    text-align: center;
    font-size: 1.5rem;
  }

  .buttonsGap {
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
  }
}