@use 'variables' as *;

@mixin outlined-button(
  $base-bg-color,
  $base-color,
  $hover-color,
  $active-bg-color,
) {
  color: $base-color;
  border: 2px solid $base-color;
  background-color: $base-bg-color;

  svg path {
    stroke: $base-color;
  }

  // ignore hover on touch devices with hover media query
  @media (hover: hover) {
    &:hover {
      color: $hover-color;
      border: 2px solid $hover-color;    
    }
    &:hover svg path {
      stroke: $hover-color;
    }
  }
  &:active {
    background-color: $active-bg-color;
  }
  &:disabled {
    cursor: not-allowed;

    color: $button-text_disabled;
    border: 2px solid $button-color_disabled;
    background-color: $button-color_disabled;
  }
}

@mixin contained-button (
  $base-bg-color,
  $base-color,
  $hover-color,
  $active-bg-color,
) {
  color: $base-bg-color;
  border: 2px solid $base-color;
  background-color: $base-color;

  svg path {
    stroke: $base-bg-color;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $hover-color;
      border: 2px solid $hover-color;
    }
  }
  &:active {
    background-color: $active-bg-color;
    border: 2px solid $active-bg-color;
  }
  &:disabled {
    cursor: not-allowed;

    color: $button-text_disabled;
    border: 2px solid $button-color_disabled;
    background-color: $button-color_disabled;
  }
}

@mixin text-button (
  $base-bg-color,
  $base-color,
  $hover-color,
  $active-text-color,
) {
  color: $base-color;
  border: 2px solid $base-bg-color;    
  background-color: inherit;

  svg path {
    stroke: $base-color;
  }

  @media (hover: hover) {
    &:hover {
      color: $hover-color;
    }
    &:hover svg path {
      stroke: $hover-color;
    }
  }
  &:active {
    color: $active-text-color;
  }
  &:active svg path {
    stroke: $active-text-color;
  }
  &:disabled {
    cursor: not-allowed;

    color: $button-text_disabled;
    border: 2px solid $base-bg-color;
  }
}

@mixin tab-button (
  $base-bg-color,
  $base-color,
  $hover-color,
) {
  color: $base-color;
  border: none;
  border-radius: unset;
  border-bottom: 3px solid $white; 
  background-color: inherit;

  @media (hover: hover) {
    &:hover {
      border-bottom: 3px solid $hover-color;
    }
  }
  &:disabled {
    cursor: not-allowed;

    color: $button-text_disabled;
    border-bottom: 3px solid $button-text_disabled;     
  }
}

@mixin spinner-icon {
  border: 2px solid $spinner-color;
  width: 1em;
  height: 1em;
  border-top-color: $spinner-spinning-part;
  border-radius: 50%;
  animation: spinner 1250ms linear 0s infinite;
  margin-right: 2px;

  @keyframes spinner {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
  }
}

@mixin linkButton($horizontalMargin: 0, $verticalMargin: 0) {
  display: flex;
  margin: $horizontalMargin $verticalMargin;
  width: fit-content;
  padding: 0 1em;
  background: $linkButton;
  border: 1px solid $linkButton-border;
  border-radius: 2px;
  align-items: center;

  &:hover {
    background: $linkButton-hover;
  }
}

@mixin table {
  text-align: center;
  margin-inline: auto;
  white-space: nowrap;
  border-spacing: 0;

  th {
    background-color: $soft-pink;
    padding: 1.1rem .7rem;
  }

  td {
    background-color: $background;
    font-size: .875rem;
    padding: .4rem .7rem;
  }

  th:first-child {
    border-top-left-radius: $table-radius;
  }

  th:last-child {
    border-top-right-radius: $table-radius;
  }

  tr td:first-child {
    border-left: 1px solid $soft-pink;
  }

  td:first-child,
  td:not(:first-child) {
    border-right: 1px solid $soft-pink;
  }

  tr:first-child td,
  tr td {
    border-bottom: 1px solid $soft-pink;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: $table-radius;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: $table-radius;
  }

  @media screen and (max-width: $tablet-screen) {
    th {
      font-weight: normal;
      font-size: .7rem;
      padding: .6rem .2rem;
    }

    td {
      font-size: .7rem;
      padding: .4rem .3rem;
    }
  }
}

@mixin infoBanner {
  text-align: center;
  color: $error-text-color;
  background-color: $error-background;
  padding: 0.75rem 0 
}