@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('../statics/fonts/poppins-latin-ext_500.woff2') format('woff2'),
         url('../statics/fonts/poppins-latin-ext_500.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('../statics/fonts/poppins-latin-ext_600.woff2') format('woff2'),
         url('../statics/fonts/poppins-latin-ext_600.woff') format('woff');
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
}

h1 {
    font-weight: 600;
}

main {
    padding: 1rem;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body, #root {
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/*Remove default styles from all links*/
:where(a) {
    color: inherit;
    text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

button {
    cursor: pointer;
}