@use '../../styles' as *;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
}

.icon {
  display: inline-flex;
  margin-right: .5rem;
}

.tab:where(.main) {
  @include tab-button(
    $white,
    $basic-red,
    $basic-red_tab-hover
  );

  &.active {
    border-bottom: 3px solid $basic-red;
  }
}

.tab:where(.context) {
  @include tab-button(
    $white,
    $chestnut,
    $chestnut-red_tab-hover
  );

  &.active {
    border-bottom: 3px solid $chestnut;
  }
}

.outlined:where(.main) {
  @include outlined-button(
    $white,
    $basic-red,
    $basic-red_outlined-hover,
    $basic-red_outlined-active
  );
}

.outlined:where(.context) {
  @include outlined-button(
    $white,
    $chestnut,
    $chestnut_outlined-hover,
    $chestnut_outlined-active
  );
}

.contained:where(.main) {
  @include contained-button(
    $white,
    $basic-red,
    $basic-red_contained-hover,
    $basic-red_contained-active
  );
}

.contained:where(.context) {
  @include contained-button(
    $white,
    $chestnut,
    $chestnut_contained-hover,
    $chestnut_contained-active
  );
}

.text:where(.main) {
  @include text-button(
    $white,
    $basic-red,
    $basic-red_text-hover,
    $basic-red_text-active
  );
}

.text:where(.context) {
  @include text-button(
    $white,
    $chestnut,
    $chestnut_text-hover,
    $chestnut_text-active
  );
}

/* SIZES */
.small {
  font-size: .95rem;
  padding: .15rem .5rem;
}

.medium {
  font-size: 1.1rem;
  padding: .25rem .75rem;
}

.large {
  font-size: 2.2rem;
  padding: .4rem 1rem;
}

/* VARIANTS */
.dashboardButton {
  display: flex;
  margin-left: auto;
}
