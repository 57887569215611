@use '../../../styles/' as *;

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
    
  .spinner {
    @include spinner-icon;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    margin-right: .5em;
  }
}
  