@use '../../styles' as *;

.offlineModeInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  height: 100vh;
}

.offlineBanner {
  @include infoBanner;
}

.dashboardLink {
  color: $chestnut;
  text-decoration: underline;
}