@use '../../styles' as *;

.dayController {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 11rem;
  
  font-size: 1.25rem;
  margin-inline: auto;
}

.icon {
  cursor: pointer;

  path:hover {
    fill: $dark-grey;
  }
}

.hide {
  visibility: hidden;
}
